import React, { Component } from 'react';
import TextInput from '../TextInput';
import Button from '../Button'
import './styles.scss';
import * as EmailValidator from 'email-validator';

export default class EmailForm extends Component {

    handleLoginSubmitted = () => {
        const { submitForm, submitMessage} = this.props;
        const userEmail = this.refs.usernameInput.getValue();

        (EmailValidator.validate(userEmail)) ? 
        (submitForm(userEmail), this.refs.usernameInput.setValue(null)) : 
        submitMessage(false);        
    };

    handleInputKeyup = (e) => {
        const { submitCleared, formMessage} = this.props;

        if (e.keyCode === 13) {
            this.handleLoginSubmitted();
        } else if (formMessage === true || formMessage === false) {
            submitCleared();
        }
    }

    render() {
        const { formMessage } = this.props;
        const nativeInputProps = {
            onKeyUp: this.handleInputKeyup
        };
        return (
            <div className='submit-wrapper'>
            
                <div className='submit-form'>
                    <TextInput
                        errorMsg={formMessage}
                        placeholderText='ENTER YOUR EMAIL HERE'
                        ref='usernameInput'
                        nativeProps={nativeInputProps}
                    />
                    <Button
                        styleClassName='login-btn'
                        text='SUBMIT'
                        clickHandler={this.handleLoginSubmitted}
                    />
                        {(function() {
                            switch(formMessage) {
                            case true:
                                return <div className='text-input__success-msg'>Please check your e-mail for further instructions</div>;
                            case false:
                                return <div className='text-input__error-msg'>Please enter a valid e-mail address</div>;
                            default:
                                return <div className='text-input__error-msg'></div>;
                            }
                        })()}
                </div>
            </div>
            
        );
    }

}