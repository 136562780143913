import createReducer from '../utils/createReducer';
import { CLOSE_MODAL, OPEN_MODAL } from '../constants/actions';

const initialState = {
  modalOpen: false,
  modalData: null,
};

export default createReducer(initialState, {
  [OPEN_MODAL]: (state, action) => ({
    ...state,
    modalOpen: true,
    modalData: action.modalData,
  }),

  [CLOSE_MODAL]: state => ({
    ...state,
    modalOpen: false,
    modalData: null,
  }),
});
