import React from 'react';
import './styles.scss';
import { withRouter } from 'react-router';
import ImageGallery from 'react-image-gallery';
import HomeCarousel from '../../components/HomeCarousel';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../components/ScrollToTop';

//Testing

const images = [
  'images/app/mainimage1.jpg',
  'images/app/mainimage2.jpg',
  'images/app/mainimage3.jpg',
  'images/app/mainimage4.jpg',
  'images/app/mainimage5.jpg',
  'images/app/mainimage6.jpg',
  'images/app/mainimage7.jpg',
];

const HomeView = () => (
  <div className="home-content-container">
    <ScrollToTop />
    <Helmet>
      <title>Ana Williamson Architect</title>
      {/* first 50/60 characters for description*/}
      {/* add in paragraph */}
      {/* alt tags for images (individually) */}
      <meta name="content" content="AWA is an award winning firm specializing in custom residential projects."/>
      <meta name="keywords" content="ana williamson architect, anna williamson architect, awarchitect, ana williamson, anna williamson, menlo park architect, palo alto architect, architect, menlo park, bay area, san francisco, modern, architect"/>
    </Helmet>

    <div className="home-main-image-container">
      <HomeCarousel
        images = {images}
      />
    </div>
  </div>
);

export default withRouter(HomeView);
