import createReducer from '../utils/createReducer';
import { PORTFOLIO_SIDE_NAV_ITEM_SELECTED } from '../constants/actions';
import { awaProjects } from '../constants/appData/projects';

const sideNavProjects = [{ text: 'HOME', redirectURL: 'Home' }];

awaProjects.forEach(({ name = null, displayName = null }) => {
  sideNavProjects.push({
    redirectURL: displayName,
    text: name,
  });
});

const initialState = {
  sideNavItems: sideNavProjects,
  selectedPortfolioSideNavItem: { text: 'Home', redirectURL: 'Home' },
};

export default createReducer(initialState, {
  [PORTFOLIO_SIDE_NAV_ITEM_SELECTED]: (
    state,
    { data: { project: selectedPortfolioSideNavItem } }
  ) => ({
    ...state,
    selectedPortfolioSideNavItem,
  }),
});
