import React from 'react';
import './styles.scss';
import MediaQuery from 'react-responsive';
import { NavLink } from 'react-router-dom';
import AppBurger from '../AppBurger';

const MainNavBar = ({ menuItemSelected, menuItems }) => (
  <React.Fragment>
    <MediaQuery minWidth={660}>
      <div className="nav-bar-menu">
        {menuItems.map(item => (
          <NavLink
            activeClassName={'nav-bar-menu__item--active'}
            className={'nav-bar-menu__item'}
            onClick={() => menuItemSelected(item)}
            key={item.title}
            to={item.redirectURL}
          >
            {item.title}
          </NavLink>
        ))}
        {/* <a className={'nav-bar-menu__flag'}>
          <img src={require('../../assets/images/flag/colombia_flag.svg')} />
        </a> */}
      </div>
    </MediaQuery>
    <MediaQuery maxWidth={659}>
      <AppBurger
        menuItemSelected={menuItemSelected}
        menuItems={menuItems}
        pageWrapId={'App'}
        outerContainerId={'awa-wrapper'}
      />
    </MediaQuery>
  </React.Fragment>
);

export default MainNavBar;
