/* React  and Redux */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import { createBrowserHistory } from 'history';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import withTracker from './components/GoogleTracker';


import './assets/styles/style.scss';
import * as images from './assets/images/';
import * as pdfs from './assets/pdfs/';

import AppLayoutContainer from './views/layout';
import HomeView from './views/home';
import WorkViewContainer from './views/work';
import ContactView from './views/contact';
import AboutViewContainer from './views/about';
import NewsView from './views/news';
import ProcessView from './views/process';

// Creates the top-level application store with middleware. Exports the store
// for use in other modules.
const store = configureStore();

const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <AppLayoutContainer>
        <Switch>
          {/* <Route exact path="/" render={() => <HomeView />} /> */}
          <Route exact path="/" component={withTracker(HomeView)} />
          <Route exact path="/portfolio" component={withTracker(WorkViewContainer)} />
          <Route
            path="/portfolio/:slug"
            render={props => (<WorkViewContainer route={props.match.params.slug} />)}
          />
          <Route path="/contact" component={withTracker(ContactView)} />
          <Route path="/studio" component={withTracker(AboutViewContainer)} />
          <Route path="/news" component={withTracker(NewsView)} />
          <Route path="/process" component={withTracker(ProcessView)} />
          <Redirect to="/" />
        </Switch>
      </AppLayoutContainer>
    </Router>
  </Provider>,
  document.getElementById('awa-wrapper')
);


////OLD SWITCH

      // <Switch>
      //     <Route exact path="/" render={() => <HomeView />} />
      //     <Route exact path="/portfolio" render={() => <WorkViewContainer />} />
      //     <Route
      //       path="/portfolio/:slug"
      //       render={props => (
      //         <WorkViewContainer route={props.match.params.slug} />
      //       )}
      //     />
      //     <Route path="/contact" render={() => <ContactView />} />
      //     <Route path="/studio" render={() => <AboutViewContainer />} />
      //     <Route path="/news" render={() => <NewsView />} />
      //     <Route path="/process" render={() => <ProcessView />} />
      //     <Redirect to="/" />
      //   </Switch>