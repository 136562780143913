import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';

export default class TextInput extends Component {

    render() {

        const { placeholderText, maxLength, type='text', nativeProps } = this.props;
        let className = 'text-input__input';

        if (this.props.styleClassName) {
            className += ' ' + this.props.styleClassName;
        }

        return (
            <div className='text-input__wrapper'>
               
                <input
                    {...nativeProps}
                    className={className}
                    placeholder={placeholderText}
                    maxLength={maxLength}
                    type={type}
                    ref='input'
                    />
            </div>
        );
    }

    getValue = () => {
        return ReactDOM.findDOMNode(this.refs.input).value;
    };

    setValue = (value) => {
        return ReactDOM.findDOMNode(this.refs.input).value = value;
    };

}