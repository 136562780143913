import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { NavLink } from 'react-router-dom';
import { GoGrabber } from 'react-icons/go';



export default class AppBurger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  showSettings(event) {
    event.preventDefault();
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  render() {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <div className={'burger-menu-container'}>

        <Menu 
          {...this.props} 
          customBurgerIcon={<GoGrabber />}
          isOpen={this.state.menuOpen}
          onStateChange={state => this.handleStateChange(state)}
        >

            {this.props.menuItems.map(item => (
              <NavLink
                activeClassName={'nav-bar-menu__item--active'}
                className={'nav-bar-menu__item'}
                onClick={() => {
                  this.props.menuItemSelected(item);
                  this.closeMenu();
                }}
                key={item.title}
                to={item.redirectURL}
              >
                {item.title}
              </NavLink>
            ))}
        </Menu>
      </div>
    );
  }
}




// const AppBurger = (props = {}) => (
//   <Menu {...props} customBurgerIcon={<GoGrabber />}>
//     {props.menuItems.map(item => (
//       <NavLink
//         activeClassName={'nav-bar-menu__item--active'}
//         className={'nav-bar-menu__item'}
//         onClick={() => {
//           props.menuItemSelected(item);
//         }}
//         key={item.title}
//         to={item.redirectURL}
//       >
//         {item.title}
//       </NavLink>
//     ))}
//   </Menu>
// );

// export default AppBurger;
