import React from 'react';
import { connect } from 'react-redux';
import AboutView from './AboutView';
import ScrollToTop from '../../components/ScrollToTop';

const AboutViewContainer = ({ aboutSideNav: { selectedSideNavItem } }) => (
  <>
    <ScrollToTop />
    <AboutView selectedSideNavItem={selectedSideNavItem} />
  </>
);

const mapStateToProps = ({ aboutSideNav }) => ({
  aboutSideNav,
});

export default connect(mapStateToProps)(AboutViewContainer);
