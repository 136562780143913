/*

8888888b.                            888
888   Y88b                           888
888    888                           888
888   d88P .d88b.   .d88b.  88888b.  888  .d88b.
8888888P" d8P  Y8b d88""88b 888 "88b 888 d8P  Y8b
888       88888888 888  888 888  888 888 88888888
888       Y8b.     Y88..88P 888 d88P 888 Y8b.
888        "Y8888   "Y88P"  88888P"  888  "Y8888
                            888
                            888
                            888

*/

export const anaProfile = {
  name: 'Ana Williamson',
  key: 'ana_williamson',
  image: 'images/people/ana_williamson.jpg',
  subHeader: ['Principal, AIA', 'Registered Architect: California'],
  bodyText: [
    'Ana exudes a visceral passion for architecture. Inspired by her Colombian homeland, her design vision is vibrant, responsive, dynamic. Over the past 20 years, her passion for the practice of architecture has yielded a close-knit community of collaborators, colleagues, and clients, who are dedicated to work that inspires.  She selects work that feeds the soul and is committed to life-long learning and is always improving her craft.  Her professional standards, love of modern design and thorough understanding of and respect for tradition are rooted in her professional provenance.' ,
    'Ana gained her first exposure to architecture from her uncle Vicente Nasi, an Italian-born architect who established a successful practice in South America and was a contemporary of Le Corbusier. Ana earned her Masters Degree in Architecture at Princeton University, and her Bachelor of Design from the University of Florida. After university, she worked with internationally influential architects Alvaro Siza Vieira in Porto, Portugal (1992 Pritzker Laureate), and Michael Graves Architect in Princeton, New Jersey (2001 AIA Gold Medal).  She began her practice in Menlo Park, shaping the Peninsula landscape for over 20 years.  When not working on projects, you will find Ana biking at whirlwind speed and often winning races.'

  ],
};

export const awaStaff = [
  {
    name: 'Ben Flatau',
    key: 'ben_flatau',
    image: 'images/people/ben_flatau.jpg',
    subHeader: ['Associate'],
    bodyText: [
      '"If you never did you should. These things are fun and fun is good"--Dr. Suess',
      'Ben constantly seeks out new ways to understand and interact with the world around him. He\'s at his best when challenged to reconsider the normative, to go outside of his comfort zone, or to simply, "make it happen." As a continual learner, Ben loves to research new techology or methods to incorporate into his work. Ben brings a playful attitude to AWA that he hopes keeps everyone on their toes and at their best.', 
      'A California licensed architect, who has been with AWA since 2011, Ben received his masters degree in Architecture from Tulane University in New Orleans. Outside of the office, Ben keeps himself occupied tinkering on home projects, hitting the tennis courts, and going on adventures with his wife and son.'
      
    ],
  },

  {
    name: 'Joe Gardella',
    key: 'joe_gardella',
    image: 'images/people/joe_gardella.jpg',
    subHeader: ['Associate'],
    bodyText: [
      'Joe brings an inquisitive spirit, attention to details, and fresh expertise his work at AWA. He is constantly seeking to deepen his knowledge about architecture and building and since joining AWA in 2012, he has risen to many challenges, becoming a highly skilled and valued project manager and designer.  Joe is well-versed in the challenges of complex renovations, small  houses, and new development and is an advocate for clients throughout the design and construction phases.', 
      'A native of the Northeast, Joe graduated in 2012 from Roger Williams University’s School of Art, Architecture, and Preservation with a Bachelors of Science and a Master’s Degree in Architecture. Joe chose Northern California in which to pursue his career for the freedom and flexibility he finds in exploring new design approaches, the varied opportunities for continuing education, and access to the extracurricular activities that he loves. Outside of the office, Joe can be found biking, hiking, skiiing, fly-fishing, or just walking his lovable dog, Kaya.'
    ],
  },

  // {
  //   name: 'Gesthimani \'Mania\' Roumpani ',
  //   key: 'mania_roumpani',
  //   image: 'images/people/mania_roumpani.jpg',
  //   subHeader: ['Associate'],
  //   bodyText: [
  //     'Mania is a high-spirited designer driven by her excitement and eagerness for constant learning. She treats spaces as containers of daily life narratives instead of passive backdrops, aiming at curating the users’ individual experiences. Mania joined AWA in 2016 and thrives in utilizing her organizational and interpersonal skills to bring out the individuality of each project. She is fascinated by the process of extracting the essence of the clients’ needs and desires, and guiding them through the life-changing journey of creating their dream home.', 
  //     'Born and raised in Greece, Mania received her 5-year diploma in Architecture from the National Technical University of Athens, before moving to California in 2015. In June 2020, Mania graduated first from UCLA’s Master of Architecture II program. Due to her active interest in visual development and the design of imaginary worlds, she continuously pursues digital media explorations through independent projects. Her work on the project “Future of the Past” was recently exhibited at the Architectural League Prize 2020 Exhibition'
  //   ],
  // },

  // {
  //   name: 'Alexander Lopez',
  //   key: 'alexander_lopex',
  //   image: 'images/people/alexander_lopez.jpg',
  //   subHeader: ['Associate'],
  //   bodyText: [
  //     'Alex’s obsession with Legos from a young age evolved into a passion for design and understanding how things are made. He relishes the detail that becomes the mark of quality, thought, and care - a visible reminder of the responsibility that comes with crafting a home. Alex joined the AWA team in 2018 and is drawn to custom residential work, and particularly the AWA process, where the design team collaborates creatively with the client to create a home reflective of their unique personalities, cultures, and aspirations.',
  //     'Alex completed his studies at Stanford University, where he earned a Bachelors of Science in Architectural Design. His upbringing in Bolivia, Mexico, Colombia, and the U.S., has instilled in him a love for travel, which is also an excuse to add to his large soccer jersey collection.  He also enjoys playing and watching sports with friends and still builds legos to this day.'
  //   ],
  // },

  {
    name: 'Tong Zou',
    key: 'tong_zou',
    image: 'images/people/tong_zou.jpg',
    subHeader: ['Associate'],
    bodyText: [
      'Tong is a passionate designer who is constantly seeking to create meaningful built experiences. Her respect for spatial orders and her love for materials and textures have guided her architectural explorations. Her favorite task at work is sketching and modeling design ideas. While managing projects, she anticipates challenges early on and finds innovative solutions that best serve the client’s needs and project schedule.', 
      'Tong joined AWA in 2019 after relocating to the Bay Area from Singapore, where she received her Masters and Bachelors Degree of Architecture from the National University of Singapore. Previous to AWA, she gained expertise in residential, multi-family and commercial projects with another firm. Outside of work, Tong is an avid traveler who can be found exploring new places, or simply enjoying a good meal with family and friends.'
    ],
  },

  {
    name: 'Tom Vogel',
    key: 'tom_vogel',
    image: 'images/people/tom_vogel.jpg',
    subHeader: ['Associate'],
    bodyText: [
      'Trained and working as a graphic designer before getting his Masters degree in Architecture, Tom innately looks at design across all scales. From posters to furniture to architectural details to buildings, the circuitous process of seeking aesthetically pleasing and functionally elegant solutions inspires him and brings an attention to detail to his work.',
      'Born and trained in Minneapolis with a stopover in New York City as a member of the winning design team of the 2018 Museum of Modern Art YAP, Tom is a recent transplant to the Bay Area drawn to the area by its beauty, culture, and long history of modern residential design.  When not in the office, Tom loves sauntering about his adopted city of Oakland on a search for good coffee, great tacos, and inspiring design work.' 

    ],
  },

  {
    name: 'Teodora Velkova',
    key: 'teodora_velkova',
    image: 'images/people/teodora_velkova.jpg',
    subHeader: ['Associate'],
    bodyText: [
      'Teodora is constantly looking for new ways to further her architectural education and experience. This has shaped her into a thoughtful and creative young designer, who constantly aims to create a user targeted experience. Teodora joined the team in early 2021 after being drawn to AWA’s design excellence, collaborative culture, client-first process.',
      'Originally from Bulgaria, Teodora received her BA in Architecture from Oxford Brookes University, Oxford, UK where she obtained her RIBA Part 1 accreditation. She later recieved her Masters in Architecture from UCLA. Before coming to the US in 2017, she took a gap year to join MAD architects in Beijing, China. When not working, Teodora loves to read, hike, watch Disney movies, and play with her puppy Thor.' 
    ],
  },

  {
    name: 'Iva Monterrubio Langrova',
    key: 'iva_monterrubio_langrova',
    image: 'images/people/iva_monterrubio_langrova.jpg',
    subHeader: ['Associate'],
    bodyText: [
      'Iva was born, raised, and architecturally shaped in Brno, The Czech Republic. She moved across the globe to expand her horizons. Little  did she know back then that her journey would lead her to earn a Bachelor of Arts in Architecture from the University of California, Berkeley. Her architectural experience spans hospitality, restaurants, and commercial residential development. Her fondness of custom residential designs led her to AWA. She has been honing her architectural skills with the AWA team since April 2021.',
      'In her spare time, Iva loves to cook and bake. Her specialty is "veganizing" all kinds of pastries and dishes. Besides her passion for food, she loves her two boxer dogs (and her husband too!). Iva starts her action-packed days with a morning run and fresh cup of coffee.' 

    ],
  },

  {
    name: 'Luna',
    key: 'luna',
    image: 'images/people/luna.jpg',
    subHeader: ['Office Dog'],
    bodyText: [
      '“So what’s wrong with making meal time a joyous occasion?” – Snoopy. ',
      'Luna, our beloved office dog, is a Catahoula (the state dog of Louisiana). She is our acting material manager–quality assurance is a top priority of Luna, so much so that she can be found regularly subjecting our office materials to various durability tests throughout the day. During her free time, she enjoys runs at the park and the occasional nap.',
    ],
  },
];

