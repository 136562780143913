import React from 'react';
import './styles.scss';
import MediaQuery from 'react-responsive';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../components/ScrollToTop';
import EmailFormContainer from '../../components/SubmitForm';


const ProcessView = () => (
  <div className="process-view-container">
    <ScrollToTop />
    <Helmet>
      <title>Our Process</title>
      <meta
        name="Process"
        content="AWA has a unique take on architecture, learn more about our process here"
      />
    </Helmet>
    <div className="process-video-container">

      <ReactPlayer
        className="process-video"
        // url="https://vimeo.com/56974716"
        url="https://www.awarchitect.com/videos/AWA_ProcessVideo_Final.mp4"
        controls={true}
        playing={false}
        width={'100%'}
        height={'100%'}
        config={{
          file: {
            attributes: {
              controlsList:'nodownload',
              disablePictureInPicture: true
            }
          }
        }}
      />
    </div>

    {/* <div className="process-team-image-container">
      <img className="process-team-image" src={'images/people/studio_2.jpg'} />
    </div> */}

    <div className="process-description-text">{processText}</div>
    <MediaQuery minWidth={900}>
      <div className="process-image-container">
        <div className="process-image" style={{ backgroundImage: 'url(images/app/process_1.jpg)' }}></div>
        <div className="process-image" style={{ backgroundImage: 'url(images/app/process_2.jpg)' }}></div>
        <div className="process-image" style={{ backgroundImage: 'url(images/app/process_3.jpg)' }}></div>
      </div>
    </MediaQuery>

    <MediaQuery minWidth={660} maxWidth={899}>
      <div className="process-image-container">
        <div className="process-image" style={{ backgroundImage: 'url(images/app/process_1.jpg)' }}></div>
      </div>
    </MediaQuery>
    
    <div className="process-email-container">
      <div className="process-email-text">
        For more information about our process and how to get started working with AWA, please enter your e-mail below for links
        to additional content and literature. 
      </div>
      <div className="process-email-form">
        <EmailFormContainer />
      </div>
    </div>
  </div>
);

export default ProcessView;

const processText = `Our design process is intuitive, optimistic, rigorous, iterative, collaborative and intensely creative– and intimately connected to our clients’ experience of the process and their completed project.

Signs of process are visible on every surface of our office–trace sketches, hand-built project models, material sample groupings–providing us and our clients numerous ways of accessing our ideas, satiating their curiosity of mind and envisioning the end result.

Our agile mind-set offers opportunity for invention and the discovery of new approaches, relative to each project’s challenges and opportunities. As the organizer and leader of the process we are valued listeners, with a heightened awareness of the big picture as well as for the finest details of implementation.

We classify our design language as expressive, spatial architecture, crafting site-specific solutions that employ light, natural materials, color and texture with the highest level of design integrity.`;

{
  /* <MediaQuery minWidth={550}>
      <div className="contact-image-container">
        <img src="/images/app/contact.jpg" className="contact-image" />
      </div>
    </MediaQuery> */
}
