import React from 'react';
import './styles.scss';
import { Helmet } from 'react-helmet';

import { awaStaff, anaProfile } from '../../constants/appData/people';

const AboutView = () => (
  <div className="studio-content-container">
    <Helmet>
      <title>Meet the AWA Team</title>
      <meta name="description" content="AWA is an award winning firm specializing in custom residential projects."/>
    </Helmet>
    <div className='studio-group-pic-container'>
      <img className="studio-group-pic-image" src={'images/people/studio_1.jpg'} />
    </div>
    <div className="studio-header-ana"> ANA WILLIAMSON, AIA </div>
    <div className="intro-studio-content-container">
      <div className="ana-studio-image-container">
        <img className="ana-studio-image" src={anaProfile.image} />
      </div>

      <div className="studio-intro-text-container">
        {anaProfile.bodyText.map((text, key) => {
            return(
              <div key={key} className='studio-intro-text-paragraph'>
                <div key={key} className="studio-intro-text"> {text} </div>
              </div>
            )
          })}
      </div>
  
    </div>

    <div className="studio-header">THE TEAM</div>

    {awaStaff.map((person, index) => {
      return (
        <div key={index} className="staff-studio-content-container">
          <div className="staff-studio-image-container">
            <img className="staff-studio-image" src={person.image} />
          </div>
          <div className="staff-studio-text-container">
            <div className="staff-about-info-text">{person.name}</div>

            {person.bodyText.map((text, key) => {
            return(
              <div key={key}>
                <div key={key} className="staff-about-text"> {text} </div>
              </div>
            )
            })}
          </div>
        </div>
      );
    })}
  </div>
);

export default AboutView;

////OLD LAYOUT /////

// const sidnavDisplay = {
//   about_awa: <AboutAwa />,
//   people: <PeopleAwaConnector />,
//   design_process: 'ami content goes here??',
//   news: 'misc awards, newsletters, etc...',
// };

// const AboutView = ({ selectedSideNavItem }) => (
//   <div className="about-content-container">
//     <div className="about-side-nav-container">
//       {' '}
//       <AboutSideNavConnector />{' '}
//     </div>
//     <div className="about-content-box-container">
//       {sidnavDisplay[selectedSideNavItem.key]}
//     </div>
//   </div>
// );

// export default withRouter(AboutView);


//images w/ alt tags
//keywords
