export const SIDE_NAV_SELECTED = 'SIDE_NAV_SELECTED';
export const APPLICATION_LOADED = 'APPLICATION_LOADED';
export const MENU_ITEM_SELECTED = 'MENU_ITEM_SELECTED';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';
export const PEOPLE_SUB_SIDE_NAV_SELECTED = 'PEOPLE_SUB_SIDE_NAV_SELECTED';
export const PORTFOLIO_SIDE_NAV_ITEM_SELECTED = 'PORTFOLIO_SIDE_NAV_ITEM_SELECTED';
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const SUBMIT_CLEARED = 'SUBMIT_CLEARED';
export const SUBMIT_MESSAGE = 'SUBMIT_MESSAGE';


