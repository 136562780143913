import React from 'react';
import './styles.scss';

class HomeCarousel extends React.Component {
	constructor (props) {
		super(props);

		this.state = {
			currentImageIndex: 0,
			projectImages: this.props.images.map((picture) => {
				const img = new Image();
				img.src = picture;
				return img
			}) 
		};

		this.nextSlide = this.nextSlide.bind(this);
	}

	nextSlide () {
		const lastIndex = this.props.images.length - 1;
		const { currentImageIndex } = this.state;
		const shouldResetIndex = currentImageIndex === lastIndex;
		const index =  shouldResetIndex ? 0 : currentImageIndex + 1;

		this.setState({
			currentImageIndex: index
		});
	}
	
    componentDidMount() {

        this.interval = setInterval(() => {
			this.nextSlide();
		}, 5000);	
	}
	
	componentWillUnmount(){
		clearInterval(this.interval)
	}
	
	render () {
		return (
			<div className="home-carousel">
				<div 
					className="home-image-slide" 
					style={{ backgroundImage: `url(${this.state.projectImages[this.state.currentImageIndex].src})` }}	
				/>	
			</div>
		);
	}
}

export default HomeCarousel;