import { combineReducers } from 'redux';
// Import all the reducers...
import app from './app';
import mainNavBar from './mainNavBar';
import aboutSideNav from './about';
import modal from './modal';
import portfolioSideNav from './portfolioSideNav';
import emailForm from './emailForm';

// Combine all reducers into a single reducer for Redux to run
export const reducer = combineReducers({
  aboutSideNav,
  app,
  mainNavBar,
  modal,
  portfolioSideNav,
  emailForm
});
