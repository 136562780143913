import React from 'react';
import './styles.scss';
import MediaQuery from 'react-responsive';
import Obfuscate from 'react-obfuscate';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../components/ScrollToTop';
import EmailFormContainer from '../../components/SubmitForm';




const ContactView = () => (
  <div className="contact-view-container">
    <ScrollToTop />
    <Helmet>
      <title>Contact Us</title>
      <meta
        name="Contact"
        content="Contact AWA for more information"
      />
    </Helmet>

    <div className="contact-main-image-container">
      <img className="contact-main-image" src={'images/app/contact_sketch.jpg'} />
    </div>

    {/* <MediaQuery minWidth={900}> */}
      <div className="contact-info-container">
        <div className="contact-info-box-container">
          <div className="contact-info-text">
            <div className="contact-address-text-container">
              {/* <div className="contact-info">
                <div className="contact-address-text">Ana Williamson Architect</div>
                <div className="contact-address-text">885 Santa Cruz Avenue, Suite A</div>
                <div className="contact-address-text"> Menlo Park, CA 94025</div>
              </div> */}
              <div className="contact-link-text">
                <div className="contact-link-special-text"> Email</div>
                <div className="contact-link-info-text">
                  <Obfuscate
                    className="contact-link-info-text"
                    email="info@awarchitect.com"
                  />
                </div>

              </div>
              <br />
              <div className="contact-link-text">
                <div className="contact-link-special-text"> Phone</div>
                <div className="contact-link-info-text">650-329-0577</div>
              </div>
              <br />
              <div className="contact-link-text">
                <div className="contact-link-special-text"> Find Us!</div>
                <div className="contact-link-info-text">
                  <a
                    href="https://goo.gl/maps/YxACHYMdgo42"
                    target="_blank"
                    className="contact-link-info-text"
                  >
                    Our Location
                  </a>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </div>
    {/* </MediaQuery> */}

    <div className="contact-email-container">
      <div className="contact-employment-text-container">
        <div className="contact-link-text">
          AWA is always looking for talented individuals to join our team. If
          you love a challenge, live for design, and aren't afraid to have some
          fun along the way, please contact us via <div className="contact-link-info-text-inline">
            <Obfuscate
              className="contact-link-info-text-inline"
              email="jobs@awarchitect.com"
            />
            
          </div>
          {/* <div className="current-job-openings">
            <p>We're currently looking to fill following roles:</p>
            <p>
              <ul className="job-posting-list">
                <li className="job-posting"><a href="pdfs/awa_business_manager.pdf" target="_blank" className="job-posting-link">Business Manager</a></li>
              </ul>
            </p>
          </div> */}
           
        </div>
        
      </div>
      <div className="contact-email-text">
        <a className="contact-link-info-text-inline" href="http://eepurl.com/gKYg6r" target="_blank"> Sign up for our Newsletter</a> to stay up to date with our latest projects, news, and adventures!
      </div>
    </div>
  </div>
);

export default ContactView;

// const ContactView = () => (
//   <div className="contact-view-container">
//     <ScrollToTop />
//     <Helmet>
//       <title>Contact Us </title>
//       <meta
//         name="Contact"
//         content="AWA is an award winning firm specializing in custom residential projects."
//       />
//     </Helmet>
    // <div className="contact-info-box-container">
    //   <div className="contact-info-text">
    //     <div className="contact-address-text-container">
    //       <div className="contact-info">
    //         <div className="contact-address-text">Ana Williamson Architect</div>
    //         <div className="contact-address-text">
    //           885 Santa Cruz Avenue, Suite A
    //         </div>
    //         <div className="contact-address-text"> Menlo Park, CA 94025</div>
    //       </div>
    //       <div className="contact-link-text">
    //         {/* <div className="contact-link-special-text"> Email</div> */}
    //         <div className="contact-link-info-text">
    //           <Obfuscate
    //             className="contact-link-info-text"
    //             email="info@awarchitect.com"
    //           />
    //         </div>
            
    //       </div>
    //       <br />
    //       <div className="contact-link-text">
    //         <div className="contact-link-info-text">
    //           650-329-0577
    //         </div>
    //       </div>
    //       <br />
    //       <div className="contact-link-text">
    //         {/* <div className="contact-link-special-text"> Find Us!</div> */}
    //         <div className="contact-link-info-text">
    //           <a
    //             href="https://goo.gl/maps/YxACHYMdgo42"
    //             target="_blank"
    //             className="contact-link-info-text"
    //           >
    //             Our Location
    //           </a>
    //         </div>
    //       </div>
    //     </div>

    //     <div className="contact-employment-text">
    //       <div>
    //         AWA is always looking for talented individuals to join our team. If
    //         you love a challenge, live for design, and aren't afraid to have some
    //         fun along the way, please contact us via the link below.
    //       </div>
    //       <div className="contact-link-info-text">
    //         <Obfuscate
    //           className="contact-link-info-text"
    //           email="jobs@awarchitect.com"
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
//     <MediaQuery minWidth={550}>
//       <div className="contact-image-container">
//         <img src="/images/app/contact.jpg" className="contact-image" />
//       </div>
//     </MediaQuery>
//   </div>
// );

// export default ContactView;
