import createReducer from '../utils/createReducer';
import { SIDE_NAV_SELECTED } from '../constants/actions';

const initialState = {
  sideNavItems: [
    { name: 'About AWA', key: 'about_awa' },
    { name: 'People', key: 'people' },
    { name: 'News', key: 'news' },
  ],

  selectedSideNavItem: { name: 'About AWA', key: 'about_awa' },
};

export default createReducer(initialState, {
  [SIDE_NAV_SELECTED]: (
    state,
    { data: { itemName: selectedSideNavItem } }
  ) => ({
    ...state,
    selectedSideNavItem,
  }),
});
