import React, { Component } from 'react';
import { connect } from 'react-redux';
import MainNavBar from './MainNavBar';
import { withRouter } from 'react-router';
import { menuItemSelected } from '../../actionCreators';

class MainNavBarContainer extends Component {
  componentDidMount() {
    window.onpopstate = () => this.forceUpdate();
  }
  render = () => {
    const { menuItems } = this.props;
    return (
      <MainNavBar menuItemSelected={menuItemSelected} menuItems={menuItems} />
    );
  };
}

const mapStateToProps = ({ mainNavBar: { menuItems } }) => ({
  menuItems,
});

export default withRouter(connect(mapStateToProps)(MainNavBarContainer));
