import {
  APPLICATION_LOADED,
  CLOSE_MODAL,
  MENU_ITEM_SELECTED,
  OPEN_MODAL,
  PEOPLE_SUB_SIDE_NAV_SELECTED,
  PORTFOLIO_SIDE_NAV_ITEM_SELECTED,
  SIDE_NAV_SELECTED,
  SUBMIT_FORM,
  SUBMIT_CLEARED,
  SUBMIT_MESSAGE
} from '../constants/actions';

export const sideNavItemSelected = itemName => ({
  type: SIDE_NAV_SELECTED,
  data: {
    itemName,
  },
});

export const loadedApplication = data => dispatch => {
  dispatch({
    type: APPLICATION_LOADED,
  });

  console.log('Application loaded...');
};

export const menuItemSelected = itemName => ({
  type: MENU_ITEM_SELECTED,
  data: {
    itemName,
  },
});

export const openModal = data => ({
  type: OPEN_MODAL,
  modalData: data,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const peopleSubSideNavItemSelected = person => ({
  type: PEOPLE_SUB_SIDE_NAV_SELECTED,
  data: {
    person,
  },
});

export const portfolioSideNavItemSelected = project => ({
  type: PORTFOLIO_SIDE_NAV_ITEM_SELECTED,
  data: {
    project,
  },
});


export function submitCleared() {

  return dispatch => {
      dispatch({
          type: SUBMIT_CLEARED,
      });
      dispatch(submitMessage(null));
  }
}

export const submitMessage= (message) => ({
  type: SUBMIT_MESSAGE,
  data: { message }
});

export function submitForm(emailAddress) {

  return dispatch => {
      dispatch({
          type: SUBMIT_FORM,
      });
      
      dispatch(submitMessage(true));

      fetch('https://arch-folio.com/api/email', {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: emailAddress, type: 'process',})
        }).then(res=>res.json())
        .then(res => console.log(res))
  }
}