import createReducer from '../utils/createReducer';
import { SUBMIT_FORM, SUBMIT_CLEARED, SUBMIT_MESSAGE } from '../constants/actions';

const initialState = {
  formSubmitted: false,
  formMessage: null
};


export default createReducer(initialState, {
  
  [SUBMIT_FORM]: (state, action) => {
    return{
      ...state,
      formSubmitted: true,
    };
  },

  [SUBMIT_CLEARED]: (state) => {
    return{
      ...state,
      formMessage: null
    };
  },

  [SUBMIT_MESSAGE]: (state, action) => {
    return{
      ...state,
      formMessage: action.data.message
    };
  },
  
})


// export default createReducer(initialState, {
//   [SUBMIT_FORM]: (state, { data: { benTest: formSubmitted } }) => ({
//     ...state,
//     formSubmitted,
//   }),
// });