import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import MediaQuery from 'react-responsive';
import './styles.scss';
import './reactImageGallery/styles.scss';
import { GoFile } from 'react-icons/go';

const className = 'scroll-carousel-project';

export default class ScrollCarouselProjectContent extends Component {
  state = {
    showText: false,
  };

  render() {
    const {
      index,
      project: { displayName, name, info_text, project_images },
    } = this.props;
    const { showText } = this.state;
    return (
      <div id={displayName} className="scroll-carousel-project">
        <MediaQuery maxWidth={768}>
          <div className={`${className}-title`}>{name}</div>
        </MediaQuery>
        <div className="gallery-container">
          {showText ? (
            <div className="project-text-container">
              <div className="project-text">{info_text}</div>
            </div>
          ) : null}
          <ImageGallery
            key={index}
            items={project_images.map(image => ({
              original: `${window.location.origin}/images/${image}`,
            }))}
            // lazyLoad={true} 
            preventDefaultTouchmoveEvent={true}
            showPlayButton={false}
            showFullscreenButton={false}
            showThumbnails={false}
            showBullets
            swipeThreshold={20}
          />
        </div>
        <MediaQuery minWidth={769}>
          <div className={`${className}-title`}>{name}</div>
        </MediaQuery>
        <div
          className={
            showText
              ? `${className}-info-icon--active`
              : `${className}-info-icon`
          }
        >
          {(info_text) ? <GoFile onClick={() => this.setState({ showText: !showText })} />  : null }
        </div>
      </div>
    );
  }
}
