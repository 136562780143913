import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import MainNavBarContainer from '../MainNavBar';
import { withRouter } from 'react-router';

const AppHeader = ({ menuItemSelected }) => (
  <div className="header-container">
    <div className="nav-button-container">
      <MainNavBarContainer />
    </div>

    <div className="header-logo-container">
      <Link
        onClick={() => {
          menuItemSelected({
            title: 'home',
            redirectURL: '/',
          });
        }}
        to={'/'}
      >
        <img className="header-logo" src={'/images/app/AWA-logo-2017.png'} />
      </Link>
    </div>
  </div>
);

export default withRouter(AppHeader);
