import React from 'react';
import './styles.scss';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../components/ScrollToTop';

import { awaNews } from '../../constants/appData/news';

const NewsView = () => (
  <div className="news-content-container">
    <ScrollToTop />
    <Helmet>
      <title>AWA News</title>
      <meta
        name="News"
        content="AWA is an award winning firm specializing in custom residential projects."
      />
    </Helmet>
    {awaNews.map(({ link, image }, index) => {
      return (
        <a
          className="news-article-container"
          key={index}
          href={link}
          target="_blank"
        >
          <div className="news-image-container">
            <img className="news-image" src={image} />
          </div>
        </a>
      );
    })}
  </div>
);

export default NewsView;
