import React, { Component } from 'react';
import { connect } from 'react-redux';
import PortfolioSideNav from './PortfolioSideNav';
import { withRouter } from 'react-router';
import { sideNavItemSelected } from '../../actionCreators';

class PortfolioSideNavConnector extends Component {
  componentDidMount() {
    window.onpopstate = () => this.forceUpdate();
  }
  render = () => {
    const { history, selectedPortfolioSideNavItem, sideNavItems } = this.props;
    return (
      <PortfolioSideNav
        sideNavItemSelected={sideNavItemSelected}
        history={history}
        selectedPortfolioSideNavItem={selectedPortfolioSideNavItem}
        sideNavItems={sideNavItems}
      />
    );
  };
}

//this is from the reducer index file
const mapStateToProps = ({
  portfolioSideNav: { selectedPortfolioSideNavItem, sideNavItems },
}) => ({ selectedPortfolioSideNavItem, sideNavItems });

//withrouter adds history object
export default withRouter(connect(mapStateToProps)(PortfolioSideNavConnector));
