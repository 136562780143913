/*

888b    888 8888888888 888       888  .d8888b.  
8888b   888 888        888   o   888 d88P  Y88b 
88888b  888 888        888  d8b  888 Y88b.      
888Y88b 888 8888888    888 d888b 888  "Y888b.   
888 Y88b888 888        888d88888b888     "Y88b. 
888  Y88888 888        88888P Y88888       "888 
888   Y8888 888        8888P   Y8888 Y88b  d88P 
888    Y888 8888888888 888P     Y888  "Y8888P"  
                                              
*/


export const awaNews = [
  {
    image: 'images/news/punch_magazine.jpg',
    link:
      'http://www.journalgraphicsdigitalpublications.com/epubs/36Media/PunchSpiritofThePeninsulaFebruary2019/#?page=78',
  },

  {
    image: 'images/news/modern_architect_podcast.jpg',
    link:
      'https://www.modlar.com/modernarchitect/86/s03-episode-42-ana-williamson/',
  },

  {
    image: 'images/news/in_menlo.jpg',
    link:
      'https://inmenlo.com/2017/11/27/ana-williamson-is-the-go-to-architect-for-menlo-park-home-building-projects/',
  },

  {
    image: 'images/news/palo_alto_weekly.jpg',
    link: 'pdfs/palo_alto_weekly.pdf',
  },

  {
    image: 'images/news/sunset.jpg',
    link: 'pdfs/Sunset_Article.pdf',
  },

  {
    image: 'images/news/atomic_ranch.jpg',
    link: 'https://www.atomic-ranch.com/summer-2016/',
  },

  {
    image: 'images/news/sfgp_2.jpg',
    link: 'https://www.aiasf.org/page/MenloOaksModern',
  },

  // {
  //   image: 'images/news/sfgp_1.jpg',
  //   link: 'http://www.google.com',
  // },

  {
    image: 'images/news/aia_honor_cloud.jpg',
    link: 'https://www.aiasmc.org/award_category/honor-award/',
  },
];
