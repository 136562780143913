import React, { Component } from 'react';
import { connect } from 'react-redux';
import EmailForm from './EmailForm';
import { submitForm, submitCleared, submitMessage } from '../../actionCreators';
import { bindActionCreators } from 'redux';
// import * as appActions from '../../actionCreators';

function select(state) {
  return {
      emailForm: state.emailForm,
  };
}

class EmailFormContainer extends Component {
  render() {
      const { dispatch } = this.props;
      return (
              <EmailForm
                  {...bindActionCreators({ submitForm, submitCleared,submitMessage }, dispatch)}
                  {...this.props.emailForm}
              />
      );
  }
}

export default connect(select)(EmailFormContainer);



// const EmailFormContainer = ({ emailForm }) => (
//   <EmailForm
//     emailForm={emailForm}
//     submitForm={submitForm}
//   />
// );

// const mapStateToProps = ({ emailForm }) => ({
//   emailForm,
// });

// export default connect(mapStateToProps)(EmailFormContainer);