import React from 'react';
import './styles.scss';

//ICONS
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaHouzz } from 'react-icons/fa';
import { FaPinterest } from 'react-icons/fa';

const AppFooter = () => (
  <div className="app-footer-container">
    <div className="footer-icon-bar">
      <div className="footer-icon">
        <a
          className="footer-icon-button"
          href={'https://www.facebook.com/anawilliamsonarchitect'}
          target="_blank"
        >
          <FaFacebookF />
        </a>
      </div>

      <div className="footer-icon">
        <a
          className="footer-icon-button"
          href={'https://www.instagram.com/anawilliamsonarchitect/'}
          target="_blank"
        >
          <FaInstagram />
        </a>
      </div>

      <div className="footer-icon">
        <a
          className="footer-icon-button"
          href={'https://www.linkedin.com/company/ana-williamson-architect'}
          target="_blank"
        >
          <FaLinkedinIn />
        </a>
      </div>

      <div className="footer-icon">
        <a
          className="footer-icon-button"
          href={'http://www.houzz.com/pro/awarchitect/ana-williamson-architect'}
          target="_blank"
        >
          <FaHouzz />
        </a>
      </div>

      <div className="footer-icon">
        <a
          className="footer-icon-button"
          href={'https://www.pinterest.com/awarchitect/'}
          target="_blank"
        >
          <FaPinterest />
        </a>
      </div>
    </div>

    <div className="footer-info-text-container">
      © {new Date().getFullYear()} Ana Williamson Architect
    </div>

    {/* <div className="footer-designed-by-container">
      Designed and Built by{' '}
      <a className="footer-link" href="http://arch-folio.com" target="_blank">
        {' '}
        Arch-Folio.com{' '}
      </a>
    </div> */}
  </div>
);

export default AppFooter;
