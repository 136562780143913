import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ScrollCarouselProject from '../../components/ScrollCarouselProject';
import ScrollToTop from '../../components/ScrollToTop';
import { Helmet } from 'react-helmet';
import { sideNavItemSelected } from '../../actionCreators';

const WorkViewContainer = ({ route, history, portfolioSideNav }) => (
  <div className="work-layout-container">
    <ScrollToTop />
    <Helmet>
      <title>Portfolio </title>
      <meta
        name="Portfolio"
        content="AWA is an award winning firm specializing in custom residential projects."
      />
    </Helmet>
    <ScrollCarouselProject
      history={history}
      route={route}
      sideNavItemSelected={sideNavItemSelected}
      {...portfolioSideNav}
    />
  </div>
);

const mapStateToProps = ({
  modal,
  portfolioSideNav: { selectedPortfolioSideNavItem },
  portfolioSideNav,
}) => ({
  modal,
  selectedPortfolioSideNavItem,
  portfolioSideNav,
});

export default withRouter(connect(mapStateToProps)(WorkViewContainer));
