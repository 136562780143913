
/*

8888888b.                   d8b                   888
888   Y88b                  Y8P                   888
888    888                                        888
888   d88P 888d888 .d88b.  8888  .d88b.   .d8888b 888888 .d8888b
8888888P"  888P"  d88""88b "888 d8P  Y8b d88P"    888    88K
888        888    888  888  888 88888888 888      888    "Y8888b.
888        888    Y88..88P  888 Y8b.     Y88b.    Y88b.       X88
888        888     "Y88P"   888  "Y8888   "Y8888P  "Y888  88888P'
                            888
                           d88P
                         888P"

*/

export const awaProjects = [


  {
    displayName: 'sunset_bliss',
    name: 'SUNSET BLISS',
    info_text: null,
    project_images: [
      'sunset_bliss/sunset_bliss_1.jpg',
      'sunset_bliss/sunset_bliss_2.jpg',
      'sunset_bliss/sunset_bliss_3.jpg',
      'sunset_bliss/sunset_bliss_4.jpg',
      'sunset_bliss/sunset_bliss_5.jpg',
      'sunset_bliss/sunset_bliss_6.jpg',
      'sunset_bliss/sunset_bliss_7.jpg',
      'sunset_bliss/sunset_bliss_8.jpg',
      'sunset_bliss/sunset_bliss_9.jpg',
      'sunset_bliss/sunset_bliss_10.jpg',
      'sunset_bliss/sunset_bliss_11.jpg',
      'sunset_bliss/sunset_bliss_12.jpg',
      'sunset_bliss/sunset_bliss_13.jpg',
      'sunset_bliss/sunset_bliss_14.jpg',
      'sunset_bliss/sunset_bliss_15.jpg',
    ],
  },

  {
    displayName: 'music_box',
    name: 'MUSIC BOX',
    info_text: `A couple came to us with the desire to update their original post and beam 1960’s home with beautiful views of the hills beyond the Ladera neighborhood. While the siting of the home and views were already wonderful features in place, it soon became clear that a full reconstruction would be needed for the property and home to truly capture the client’s needs and reflect their uniqueness and lifestyle. Scientists by day, the clients wanted a space where they could retreat to enjoy their passions of music, dance, and performance.
    Our collaboration resulted in a modern 3 bedroom, 2 bath home with an open plan in the public spaces amenable to switching between cozy living quarters and an entertainment and performance venue, despite the home’s small footprint. In addition to capturing the view, the main space’s floor to ceiling windows allow the exterior to become part of the living room as well as nature’s backdrop for performances. The convex roof shape of the home with a stained wood ceiling evokes the wooden curves of the client’s harps and provides excellent acoustics for their rehearsals and performances.
    The exterior materials of the residence are an interplay of semi-solid wood stained vertical paneling and travertine that highlight the different volumes of the building. The landscape team’s work captures the natural beauty of the property and sight-lines while also providing hardscaped moments for contemplating art, a complement to the client’s pursuit and endorsement of the arts`,
    project_images: [
      'music_box/music_box_1.jpg',
      'music_box/music_box_2.jpg',
      'music_box/music_box_3.jpg',
      'music_box/music_box_4.jpg',
      'music_box/music_box_5.jpg',
      'music_box/music_box_6.jpg',
      'music_box/music_box_7.jpg',
      'music_box/music_box_8.jpg',
      'music_box/music_box_9.jpg',
      'music_box/music_box_10.jpg',
      'music_box/music_box_11.jpg'
    ],
  },

  {
    displayName: 'bear_gulch',
    name: 'BEAR GULCH',
    info_text: null,
    project_images: [
      'bear_gulch/bear_gulch_1.jpg',
      'bear_gulch/bear_gulch_2.jpg',
      'bear_gulch/bear_gulch_3.jpg',
      'bear_gulch/bear_gulch_4.jpg',
      'bear_gulch/bear_gulch_5.jpg',
      'bear_gulch/bear_gulch_6.jpg',
      'bear_gulch/bear_gulch_7.jpg',
    ],
  },

  {
    displayName: 'red_lady',
    name: 'RED LADY',
    info_text: null,
    project_images: [
      'red_lady/red_lady_1.jpg',
      'red_lady/red_lady_2.jpg',
      'red_lady/red_lady_3.jpg',
      'red_lady/red_lady_4.jpg',
      'red_lady/red_lady_5.jpg',
      'red_lady/red_lady_6.jpg',
      'red_lady/red_lady_7.jpg',
      'red_lady/red_lady_8.jpg',
      'red_lady/red_lady_9.jpg',
      'red_lady/red_lady_10.jpg',
      'red_lady/red_lady_11.jpg',
      'red_lady/red_lady_12.jpg',
      'red_lady/red_lady_13.jpg',
      'red_lady/red_lady_14.jpg',
      'red_lady/red_lady_15.jpg',
      'red_lady/red_lady_16.jpg',
      'red_lady/red_lady_17.jpg',
      'red_lady/red_lady_18.jpg',
      'red_lady/red_lady_19.jpg',
      'red_lady/red_lady_20.jpg',
    ],
  },

  {
    displayName: 'earth_garden',
    name: 'EARTH | GARDEN',
    info_text: null,
    project_images: [
      'earth_garden/earth_garden_1.jpg',
      'earth_garden/earth_garden_2.jpg',
      'earth_garden/earth_garden_3.jpg',
      'earth_garden/earth_garden_4.jpg',
      'earth_garden/earth_garden_5.jpg',
      'earth_garden/earth_garden_6.jpg',
      'earth_garden/earth_garden_7.jpg',
      'earth_garden/earth_garden_8.jpg',
      'earth_garden/earth_garden_9.jpg',
      'earth_garden/earth_garden_10.jpg',
      'earth_garden/earth_garden_11.jpg',
      'earth_garden/earth_garden_12.jpg',
      'earth_garden/earth_garden_13.jpg',
      'earth_garden/earth_garden_14.jpg',
      'earth_garden/earth_garden_15.jpg',
    ],
  },

  {
    displayName: 'no_1475',
    name: 'NO. 1475',
    info_text: `What started off as a small kitchen remodel turned into a 424 SF addition and an entire remodel of the existing 3,478 SF three story house.  While wanting to keep the traditional East Coast saltbox exterior, the clients wanted to create a modern, warm, family-friendly interior.  Square footage was added at the Kitchen and Laundry/ Mudroom at the First Floor and at the back of the house to create a larger Master Bathroom and ensuite Bedrooms at the Second Floor.
                The interior of the house was taken down to the studs to allow a clean slate of materials to be re- constructed – both for aesthetic and constructability reasons.Both flights of internal stairs were re - built allowing the vertical circulation core to be opened up visually by removing interior support walls.Practicality was an important aspect, as decisions were made for materials throughout the house – the goal being low maintenance and long warranties.`,
    project_images: [
      'oakhurst/oakhurst_1.jpg',
      'oakhurst/oakhurst_2.jpg',
      'oakhurst/oakhurst_3.jpg',
      'oakhurst/oakhurst_4.jpg',
      'oakhurst/oakhurst_5.jpg',
      'oakhurst/oakhurst_6.jpg',
      'oakhurst/oakhurst_7.jpg',
      'oakhurst/oakhurst_8.jpg',
      'oakhurst/oakhurst_9.jpg',
      'oakhurst/oakhurst_10.jpg',
    ],
  },

  {
    displayName: 'eichler_redux',
    name: 'EICHLER REDUX',
    info_text:  `Our client acquired this 1960’s Eichler in its original condition over 10 years ago.  He entertained several expansion ideas over those years of living in the house, but held back and resisted any change that might overwhelm the original mid-century character.  We developed a solution that provided an even better connection to the thickly wooded outdoor garden, with a modest addition that radically improved the original flow of the house and its landscape.
    The initial design objective wasn’t clearly delineated in our first meetings with our client.  We generated several preliminary concepts as the scope fluctuated between a minimal remodel and a more comprehensive undertaking.  A challenge was the unusual siting of the original construction, effectively placed sideways on a deeper-than-wide Menlo Oaks lot.  Ultimately, after several schematic design explorations that balanced the original Eichler concepts with the clients desire for some additional living space, we established a program with a clear path to create a direct connection to the backyard garden that had been previously overlooked.  We achieved this deliberate connection by introducing a new Family Room hub which opened the rear (bedroom) area of the house towards the beautiful backyard of mature oaks.   This gesture is further emphasized by the gently sloping roofline that reaches upward directing the view towards the upper tree canopy and sky. The slim-line steel doors and windows of the addition offer an elegant counterpoint to the traditional wood structure, emphasizing lightness and transparency. 
    The addition of just 500SF to the existing floor plan completely transformed the original flow of the house, and made it possible to integrate the backyard and pool area with the remainder of the house.  The material palette was kept simple in order to maintain compatibility with the original construction, using concrete floors to match existing, white walls, concrete countertops for bathroom vanities,  local Heath ceramic tile for the shower walls and reclaimed walnut sliding panels in the family room.`,
    project_images: [
      'menlo_oaks_2/menlo_oaks_2_1.jpg',
      'menlo_oaks_2/menlo_oaks_2_2.jpg',
      'menlo_oaks_2/menlo_oaks_2_3.jpg',
      'menlo_oaks_2/menlo_oaks_2_4.jpg',
      'menlo_oaks_2/menlo_oaks_2_5.jpg',
      'menlo_oaks_2/menlo_oaks_2_6.jpg',
      'menlo_oaks_2/menlo_oaks_2_7.jpg',
    ],
  },

  {
    displayName: 'moto_house',
    name: 'MOTO HOUSE',
    info_text:
      'The MOTO House is a modest 1,600 square foot home with 1,200 square foot basement sitting on a tight corner lot in the Willow Glen neighborhood of San Jose. The clients, a young couple, desired a modern, private, light filled home that requires little maintenance with an intimate personal feel reflecting their interests and active lifestyle. Privacy was a key factor in the design, which often runs counter to letting light into the home, however to achieve both goals a courtyard concept was developed with high clerestory windows along the street façade. The small rear courtyard is surrounded by large glass doors and windows filling the interior spaces with light, and allowing the front walls of the home to have less openings. The major factor in material choices was that they require little to no maintenance or upkeep so a palette of raw concrete, stucco, and accents of corrugated metal or raw steel was developed for both the interior and exterior of the home. A key requirement of the project was the need for a shop and place to store tools, equipment, and motorcycles in a secure manner. The size of the lot limited its location, so the decision was made to place it underneath the garage with a hydraulic lift to easily maneuver items from the shop to the street.',
    project_images: [
      'dale/dale_2.jpg',
      'dale/dale_1.jpg',
      'dale/dale_3.jpg',
      'dale/dale_4.jpg',
      'dale/dale_5.jpg',
      'dale/dale_6.jpg',
      'dale/dale_7.jpg',
      'dale/dale_8.jpg',
      'dale/dale_9.jpg',
      'dale/dale_10.jpg',
    ],
  },


  {
    displayName: 'hill_house',
    name: 'HILL HOUSE',
    info_text: null,
    project_images: [
      'patton/patton_1.jpg',
      'patton/patton_2.jpg',
      'patton/patton_3.jpg',
      'patton/patton_4.jpg',
      'patton/patton_5.jpg',
      'patton/patton_6.jpg',
      'patton/patton_7.jpg',
      'patton/patton_8.jpg',
      'patton/patton_9.jpg',
      'patton/patton_10.jpg',
    ],
  },

  {
    displayName: 'hot',
    name: 'H.O.T.',
    info_text: 'The client’s mantra “Open, honest, and transparent” was the guide for this design throughout the project. Challenging in terms of buildable area as well as preservation of the oak trees, the L-shaped lot dictated a linear two-story scheme at its vertex. The Connect prefab guest house served as a residence for the homeowner during construction, allowing their active involvement in the building process. The form of the main residence was inspired by the simplicity of a traditional farmhouse, with interiors detailed in a modern sensibility.',
    project_images: [
      'menlo_oaks_3/menlo_oaks_3_1.jpg',
      'menlo_oaks_3/menlo_oaks_3_2.jpg',
      'menlo_oaks_3/menlo_oaks_3_3.jpg',
      'menlo_oaks_3/menlo_oaks_3_4.jpg',
      'menlo_oaks_3/menlo_oaks_3_5.jpg',
      'menlo_oaks_3/menlo_oaks_3_6.jpg',
      'menlo_oaks_3/menlo_oaks_3_7.jpg',
      'menlo_oaks_3/menlo_oaks_3_8.jpg',
      'menlo_oaks_3/menlo_oaks_3_9.jpg',
      'menlo_oaks_3/menlo_oaks_3_10.jpg',
      'menlo_oaks_3/menlo_oaks_3_11.jpg',
      'menlo_oaks_3/menlo_oaks_3_12.jpg',
      'menlo_oaks_3/menlo_oaks_3_13.jpg',
      'menlo_oaks_3/menlo_oaks_3_14.jpg',
      'menlo_oaks_3/menlo_oaks_3_15.jpg',
      'menlo_oaks_3/menlo_oaks_3_16.jpg',
    ],
  },

  {
    displayName: 'tree_top',
    name: 'TREE TOP',
    info_text: null,
    project_images: [
      'tree_top/tree_top_1.jpg',
      'tree_top/tree_top_2.jpg',
      'tree_top/tree_top_3.jpg',
      'tree_top/tree_top_4.jpg',
      'tree_top/tree_top_5.jpg',
      'tree_top/tree_top_6.jpg',
      'tree_top/tree_top_7.jpg',
      'tree_top/tree_top_8.jpg',
      'tree_top/tree_top_9.jpg',
      'tree_top/tree_top_10.jpg',
      'tree_top/tree_top_11.jpg',
    ],
  },

  {
    displayName: 'mod_pod',
    name: 'MOD POD',
    info_text: null,

    project_images: [
      'la_cuesta/la_cuesta_1.jpg',
      'la_cuesta/la_cuesta_2.jpg',
      'la_cuesta/la_cuesta_3.jpg',
      'la_cuesta/la_cuesta_4.jpg',
      'la_cuesta/la_cuesta_5.jpg',
      'la_cuesta/la_cuesta_6.jpg',
      'la_cuesta/la_cuesta_7.jpg',
      'la_cuesta/la_cuesta_8.jpg',
    ],
  },

  {
    displayName: 'sun_kissed',
    name: 'SUN KISSED',
    info_text: null,
    project_images: [
      'balsamina/balsamina_1.jpg',
      'balsamina/balsamina_2.jpg',
      'balsamina/balsamina_3.jpg',
      'balsamina/balsamina_4.jpg',
      'balsamina/balsamina_5.jpg',
      'balsamina/balsamina_6.jpg',
      'balsamina/balsamina_7.jpg',
      'balsamina/balsamina_8.jpg',
      'balsamina/balsamina_9.jpg',
    ],
  },

  {
    displayName: 'modern_family',
    name: 'MODERN FAMILY',
    info_text: 'Set in the bottom of a shallow ravine, the Erica Residence is a distinctly modern courtyard house that spreads out over its site following the sloping, woody terrain. Architecturally, connection to the outdoors and creating a variety of spatial qualities – intimate and grand; private and public – were guiding principles in the design process. Together with the client, the architects developed a clear language and crisp details that highlight the beauty of the materials while being mindful of the practical demands of a busy family.',
    project_images: [
      'erica/erica_1.jpg',
      'erica/erica_2.jpg',
      'erica/erica_3.jpg',
      'erica/erica_4.jpg',
      'erica/erica_5.jpg',
      'erica/erica_6.jpg',
      'erica/erica_7.jpg',
      'erica/erica_8.jpg',
      'erica/erica_9.jpg',
      'erica/erica_10.jpg',
      'erica/erica_11.jpg',
    ],
  },

  {
    displayName: 'live_play',
    name: 'LIVE | PLAY',
    info_text: `This project was a sensitive remodel of one of the client's childhood home, designed by his father. The goal of the project
                was to update the 1950's home with modern amenities while preserving the mid-century charactaristic and soul of the home that had spanned the decades. In contrast, we were tasked to convert their existing garage into a music studio for jam and practice sessions. 
                Since the music studio was to be new, we were encouraged to express our clients love for the bold, colorful, and fanciful. This interplay between reverance for the old and the spectacular new allowed us to create a language to resolve the seemingly competing ideas of preservation and provocation. 
                The resulting project hopes to embody our client's respect for the past and their bold and adventurous spirit of the now...and beyond! `,

    project_images: [
      'barbara/barbara_1.jpg',
      'barbara/barbara_2.jpg',
      'barbara/barbara_3.jpg',
      'barbara/barbara_4.jpg',
      'barbara/barbara_5.jpg',
      'barbara/barbara_6.jpg',
      'barbara/barbara_7.jpg',
      'barbara/barbara_8.jpg',
      'barbara/barbara_9.jpg',
      'barbara/barbara_10.jpg',
      'barbara/barbara_11.jpg',
    ],
  },

  {
    displayName: 'bright',
    name: 'BRIGHT ON PRESIDIO',
    info_text: null,
    project_images: [
      'bright/bright_1.jpg',
      'bright/bright_2.jpg',
      'bright/bright_3.jpg',
      'bright/bright_4.jpg',
      'bright/bright_5.jpg',
      'bright/bright_6.jpg',
    ],
  },

  {
    displayName: 'details',
    name: 'DETAILS',
    info_text: null,
    project_images: [
      'detail/detail_2.jpg', 
      'detail/detail_1.jpg', 
      'detail/detail_3.jpg',
      'detail/detail_4.jpg',
      'detail/detail_5.jpg', 
      'detail/detail_6.jpg', 
      'detail/detail_7.jpg',
      'detail/detail_8.jpg'
    ],
  },
];

// export const awaProjects = [
//   {
//     displayName: 'Moto_House',
//     name: 'Moto House',
//     type: 'scroll_image',
//     main_image: 'dale/dale_index.jpg',
//     project_info: {
//       location: 'San Jose, CA',
//       type: 'Remodel',
//       date_completed: '2017',
//       size: '3,330 SF',
//       team: [
//         {
//           Role: 'Contractor',
//           Company: 'Mediterraneo Design Build',
//         },
//       ],
//     },
//     info_text:
// 'Dale is a modest 1,600 square foot home with 1,200 square foot basement sitting on a tight corner lot in the Willow Glen neighborhood of San Jose. The clients, a young couple, desired a modern, private, light filled home that requires little maintenance with an intimate personal feel reflecting their interests and active lifestyle. Privacy was a key factor in the design, which often runs counter to letting light into the home, however to achieve both goals a courtyard concept was developed with high clerestory windows along the street façade. The small rear courtyard is surrounded by large glass doors and windows filling the interior spaces with light, and allowing the front walls of the home to have less openings.',
//     project_images: [
//       'dale/dale_1.jpg',
//       'dale/dale_2.jpg',
//       'dale/dale_3.jpg',
//       'dale/dale_4.jpg',
//       'dale/dale_5.jpg',
//       'dale/dale_6.jpg',
//       'dale/dale_7.jpg',
//       'dale/dale_8.jpg',
//       'dale/dale_9.jpg',
//     ],
//   },

//   {
//     displayName: 'Rammed_Earth',
//     name: 'Rammed Earth',
//     type: 'scroll_image',
//     main_image: 'continental/continental_index.jpg',
//     project_info: {
//       location: 'Menlo Park, CA',
//       type: 'New Construction',
//       date_completed: '2017',
//       size: '4,380 SF',
//       team: [
//         {
//           Role: 'Contractor',
//           Company: 'Coast to Coast Construction',
//         },
//       ],
//     },
//     info_text: 'NEED INFO HERE!!!',
//     project_images: [
//       'continental/continental_1.jpg',
//       'continental/continental_2.jpg',
//       'continental/continental_3.jpg',
//       'continental/continental_4.jpg',
//       'continental/continental_5.jpg',
//       'continental/continental_6.jpg',
//       'continental/continental_7.jpg',
//       'continental/continental_8.jpg',
//       'continental/continental_9.jpg',
//       'continental/continental_10.jpg',
//     ],
//   },

//   {
//     displayName: 'Barbara',
//     name: 'Barbara',
//     type: 'scroll_image',
//     main_image: 'barbara/barbara_index.jpg',
//     project_info: {
//       location: 'Palo Alto, CA',
//       type: 'Remodel / Addition',
//       date_completed: '2015',
//       size: '2,565 SF',
//       team: [
//         {
//           Role: 'Contractor',
//           Company: 'Korfhage Construction',
//         },
//       ],
//     },
//     info_text: 'NEEDS INFO HERE!!!',
//     project_images: [
//       'barbara/barbara_1.jpg',
//       'barbara/barbara_2.jpg',
//       'barbara/barbara_3.jpg',
//       'barbara/barbara_4.jpg',
//       'barbara/barbara_5.jpg',
//       'barbara/barbara_6.jpg',
//       'barbara/barbara_7.jpg',
//       'barbara/barbara_8.jpg',
//       'barbara/barbara_9.jpg',
//       'barbara/barbara_10.jpg',
//       'barbara/barbara_11.jpg',
//     ],
//   },

//   {
//     displayName: 'Patton',
//     name: 'Patton',
//     type: 'carousel',
//     main_image: 'patton/patton_index.jpg',
//     project_info: {
//       location: 'Hillsborough, CA',
//       type: 'New Construction',
//       date_completed: '2016',
//       size: '3,943 SF',
//       team: [
//         {
//           Role: 'Contractor',
//           Company: 'von Clemm Construction',
//         },
//       ],
//     },
//     info_text:
//       'What makes Ana special is that she applies her aesthetic sense and technical skills to both the exterior and interior of a home to create a unified whole that represents the best of modern California design. The modern ranch house she designed for me has a clean, sophisticated look, is filled with light, has great sight lines, and uses beautiful materials. Importantly, it’s not a museum, but a place that feels warm and meant for people to live in. Ana is also a pleasure to work with — in addition to bringing positive energy and great listening skills, she approaches a project with a combination of creative genius and practicality. I made what turned out to be a very good decision to have her involved throughout the construction. Her guidance and detailed plans were extremely helpful and ensured that the finished product was true to what we had envisioned.',
//     project_images: [
//       'patton/patton_1.jpg',
//       'patton/patton_2.jpg',
//       'patton/patton_3.jpg',
//       'patton/patton_4.jpg',
//       'patton/patton_5.jpg',
//       'patton/patton_6.jpg',
//       'patton/patton_7.jpg',
//       'patton/patton_8.jpg',
//       'patton/patton_9.jpg',
//       'patton/patton_10.jpg',
//       'patton/patton_11.jpg',
//       'patton/patton_12.jpg',
//       'patton/patton_13.jpg',
//       'patton/patton_14.jpg',
//       'patton/patton_15.jpg',
//       'patton/patton_16.jpg',
//     ],
//   },

//   {
//     displayName: 'Erica',
//     name: 'Erica',
//     type: 'carousel',
//     main_image: 'erica/erica_index.jpg',
//     project_info: {
//       location: 'Portola Valley, CA',
//       type: 'New Construction',
//       date_completed: '2015',
//       size: '3,344 SF',
//       team: [
//         {
//           Role: 'Contractor',
//           Company: 'Coast to Coast Development',
//         },
//       ],
//     },
//     info_text:
//       'Set in the bottom of a shallow ravine, the Erica Residence is a distinctly modern courtyard house that spreads out over its site following the sloping, woody terrain. Architecturally, connection to the outdoors and creating a variety of spatial qualities – intimate and grand; private and public – were guiding principles in the design process. Together with the client, the architects developed a clear language and crisp details that highlight the beauty of the materials while being mindful of the practical demands of a busy family.',
//     project_images: [
//       'erica/erica_1.jpg',
//       'erica/erica_2.jpg',
//       'erica/erica_3.jpg',
//       'erica/erica_4.jpg',
//       'erica/erica_5.jpg',
//       'erica/erica_6.jpg',
//       'erica/erica_7.jpg',
//       'erica/erica_8.jpg',
//       'erica/erica_9.jpg',
//       'erica/erica_10.jpg',
//       'erica/erica_11.jpg',
//     ],
//   },

//   {
//     displayName: 'HOT',
//     name: 'H.O.T ',
//     type: 'carousel',
//     main_image: 'menlo_oaks_3/menlo_oaks_3_index.jpg',
//     project_info: {
//       location: 'Menlo Park, CA',
//       type: 'New Construction',
//       date_completed: '2015',
//       size: '3,646 SF',
//       team: [
//         {
//           Role: 'Contractor',
//           Company: 'von Clemm Construction',
//         },
//       ],
//     },
//     info_text:
//       'The intent of the Menlo Oaks project was to design a simple, unassuming, well-functioning home with ample natural light and an easy relationship with the outdoors. High ceilings, tall windows, and abundant south-facing glazing combine to fill the interior spaces with light and views to the landscape.  Natural materials and a soothing color palette were used throughout the house, paying special attention to creating both intimate and communal spaces. Modern details harmonize with the traditional material palette. The calm presence of the main residence, nestled within the large canopy of existing oaks, and its relationship to the site and guest cottage belies the complexity of the design and construction process, and obscures the spirit of experimentation that permeated all aspects of this project.',
//     project_images: [
//       'menlo_oaks_3/menlo_oaks_3_6.jpg',
//       'menlo_oaks_3/menlo_oaks_3_2.jpg',
//       'menlo_oaks_3/menlo_oaks_3_3.jpg',
//       'menlo_oaks_3/menlo_oaks_3_4.jpg',
//       'menlo_oaks_3/menlo_oaks_3_5.jpg',
//       'menlo_oaks_3/menlo_oaks_3_1.jpg',
//       'menlo_oaks_3/menlo_oaks_3_7.jpg',
//       'menlo_oaks_3/menlo_oaks_3_8.jpg',
//       'menlo_oaks_3/menlo_oaks_3_9.jpg',
//       'menlo_oaks_3/menlo_oaks_3_10.jpg',
//       'menlo_oaks_3/menlo_oaks_3_11.jpg',
//       'menlo_oaks_3/menlo_oaks_3_12.jpg',
//       'menlo_oaks_3/menlo_oaks_3_13.jpg',
//       'menlo_oaks_3/menlo_oaks_3_14.jpg',
//       'menlo_oaks_3/menlo_oaks_3_15.jpg',
//       'menlo_oaks_3/menlo_oaks_3_16.jpg',
//       'menlo_oaks_3/menlo_oaks_3_17.jpg',
//       'menlo_oaks_3/menlo_oaks_3_18.jpg',
//     ],
//   },
// ];
